<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showPermissionModal(record.slug)" v-if="record.slug !== 'tenant_admin'">权限设置</a>
        </a-space>
      </span>
    </a-table>

    <permission
      v-if="isShowPermissionModal"
      :visible.sync="isShowPermissionModal"
      :id="showingPermissionSlug"
      :is-system="true"
      @completed="fetchData"
    />

  </div>
</template>

<script>
import { findSystemRoles } from '@/api/role'
export default {
  components: {
    Permission: () => import('@/views/roles/Permission')
  },
  data() {
    return {
      isShowPermissionModal: false,
      showingPermissionSlug: undefined,
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name'
        },
        {
          title: '账号数量',
          dataIndex: 'user_count'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ],
      data: [],
      loading: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showPermissionModal(slug) {
      this.showingPermissionSlug = slug
      this.isShowPermissionModal = true
    },

    submitSearch(search) {
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSystemRoles(Object.assign({}, this.query)).then((res) => {
        this.data = res.data
        this.loading = false
      })
    }
  }
}

</script>
